<template>
    <el-dialog title="查看" width="70%" top="2vh" :close-on-click-modal="false" :visible.sync="visible">
        <div class="dialogBox">
            <el-descriptions title="订单信息" :column="2" class="b_b p_b1">
                <el-descriptions-item label="订单编号">{{info.outTradeNo}}</el-descriptions-item>
                <el-descriptions-item label="下单时间">{{info.createTime}}</el-descriptions-item>
                <el-descriptions-item label="平台交易号">{{info.tradeNo}}</el-descriptions-item>
                <el-descriptions-item label="支付时间">{{info.payDate}}</el-descriptions-item>
                <el-descriptions-item label="订单状态">
                    <el-tag size="small" v-if="info.payState">
                        {{$dictUtils.getDictLabel("payState",info.payState,'')}}
                    </el-tag>
                </el-descriptions-item>
                <el-descriptions-item label="退款订单号" v-if="info.payState == 2 || info.payState == 3">
                    {{info.outRequestNo}}
                </el-descriptions-item>
                <el-descriptions-item label="提交退款时间" v-if="info.payState == 2 || info.payState == 3">
                    {{info.createOutRequestDate}}
                </el-descriptions-item>
                <el-descriptions-item label="退款完成时间" v-if="info.payState == 3">{{info.outRequestDate}}
                </el-descriptions-item>
                <el-descriptions-item label="总价（元）">{{info.money}}</el-descriptions-item>
                <el-descriptions-item label="发票类型">{{$dictUtils.getDictLabel("billType",info.billType,'')}}
                </el-descriptions-item>
                <el-descriptions-item label="税号" v-if="info.billType == 2">{{info.dutyParagraph}}</el-descriptions-item>
                <el-descriptions-item label="抬头" v-if="info.billType == 2">>{{info.lookUp}}</el-descriptions-item>
            </el-descriptions>
            <el-descriptions title="患者信息" :column="2" class="b_b p_t2 p_b1">
                <el-descriptions-item label="患者姓名">{{info.patientName}}</el-descriptions-item>
                <el-descriptions-item label="病历号">{{info.medicalRecordNumber}}</el-descriptions-item>
                <el-descriptions-item label="城市">{{info.cityName}}</el-descriptions-item>
                <el-descriptions-item label="医院">{{info.hospitalName}}</el-descriptions-item>
                <el-descriptions-item label="科室">{{info.departmentName}}</el-descriptions-item>
                <el-descriptions-item label="标配项">
                    <el-tag size="small" class="m_r1" v-for="(item, index) in info.mingenDepartmentRequiredVoList" :key="index">
                        {{item.requiredName}}
                    </el-tag>
                </el-descriptions-item>
                <el-descriptions-item label="可选项">
                    <el-tag size="small" class="m_r1" v-for="(item, index) in info.mingenDepartmentOptionalVoList" :key="index">
                        {{item.optionalName}}
                    </el-tag>
                </el-descriptions-item>
            </el-descriptions>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="visible = false">关闭</el-button>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        data() {
            return {
                visible: false,
                info: {},
            }
        },
        methods: {
            init(row) {
                this.visible = true
                this.info = row
            },
        }
    }
</script>

<style scoped>

</style>
